import React from "react"
import { css } from "@emotion/core"

import Layout from "./layout"
import Head from "./head"
import PageHeading from "./pageHeading"

import videos from '../../ben-edit/videos.json'

export default ({ data, pageContext }) => (
  <Layout noWidth="true">
    <Head
      title={pageContext.category.name}
    />

    <PageHeading text={pageContext.category.name} />

    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 1.5rem;
        grid-column-gap: 2rem;
        margin-bottom: 2rem;

        @media (max-width: 900px) {
          grid-template-columns: 1fr;
        }
      `}
    >
      {videos.map(video => {
          if(video.category !== pageContext.category.name) return null

          return (
            <div
              css={css`
                width: 100%;
                background-color: var(--background-light);
                padding: .8rem;
                padding-bottom: 1rem;
                box-shadow: 2px 4px 16px black;
              `}
              key={video.video_key}
            >
              <div
                css={css`
                  position: relative;
                  padding-bottom: 56.25%;
                  height: 0;
                  overflow: hidden;
                `}
              >
                <iframe
                  frameBorder="0"
                  allowFullScreen
                  title={video.title}
                  src={isNaN(video.video_key) ? "https://youtube.com/embed/" + video.video_key : "https://player.vimeo.com/video/" + video.video_key}
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  `}
                />
              </div>

              <p
                css={css`
                  text-align: center;
                  margin-top: .7rem;
                  color: var(--text-light);
                  letter-spacing: .03rem;
                  font-weight: 500;
                `}
              >
                {video.title}
              </p>
            </div>
          )
      })}
    </div>

  </Layout>
)
