import React from "react"
import { css } from "@emotion/core"

export default ({ text }) => (
  <h1
    css={css`
     margin: 0 auto;
      font-size: 24px;
      font-weight: 700;
      padding: 1.5rem;
      border-radius: .5rem;
      margin-bottom: 1rem;
      max-width: 768px;
      text-align: center;
    `}
  >
    {text}
  </h1>
)